import { useIntl } from 'react-intl';

import { FontSizes, FontWeights, Text } from '@calm-web/design-system';

import { useFormattedCurrency } from '@/hooks/purchase';
import { PlanSku } from '@/hooks/purchase/usePlanSkuToggle';

import messages from './messages';
import { PlanSkuInterior, PlanSkuLeft, PlanSkuRight, TrialBadge, Wrapper } from './styles';

export type PlanSkuItemProps = PlanSku & {
	onClick?: (id: PlanSkuItemProps['id']) => void;
};

const PlanSkuItem = ({
	duration,
	id,
	originalPrice,
	price,
	pricePerMonth,
	active,
	onClick = () => {},
	badge,
	priceWithUnit,
}: PlanSkuItemProps) => {
	const formatCurrency = useFormattedCurrency();
	const { formatMessage } = useIntl();

	return (
		<Wrapper
			role="radio"
			name="plan sku"
			isActive={active}
			onPress={() => onClick(id)}
			activeBackgroundColor="white"
			inactiveBackgroundColor="white"
			aria-checked={active}
			focusRingVariant="dark"
			borderWidth={2}
			borderColor={active ? 'focusRingDark' : 'inputBgDisabled'}
		>
			<PlanSkuInterior>
				<PlanSkuLeft>
					<Text size={FontSizes.base} weight={FontWeights.Demi}>
						{duration}
						{originalPrice && <br />}
					</Text>
					<Text size={FontSizes.sm}>
						{originalPrice !== price && (
							<>
								<s>{originalPrice && formatCurrency(originalPrice)}</s>{' '}
							</>
						)}
						{price && priceWithUnit}
					</Text>
				</PlanSkuLeft>
				{pricePerMonth && (
					<PlanSkuRight>
						{formatCurrency(pricePerMonth)}/{formatMessage(messages.monthlyAbbreviation)}
					</PlanSkuRight>
				)}
			</PlanSkuInterior>
			{badge && (
				<TrialBadge>
					<Text size={FontSizes.sm} color="white">
						{badge}
					</Text>
				</TrialBadge>
			)}
		</Wrapper>
	);
};

export default PlanSkuItem;
